// src/aibooks/PerformingMedia/PerformingMedia.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AppBar, Toolbar, Typography, Switch, Box, TextField, IconButton, Button, Collapse } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import animationVideo from '../../assets/animation.mp4';
import copertinaImage from '../../assets/copertinaperformingmedia.jpg';
import userAvatar from '../../assets/user.png';
import botAvatar from '../../assets/bot.png';
import Sidebar from '../../components/Sidebar';
import './PerformingMedia.css';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MessageBubble from '../../components/MessageBubbles';
import { useSession } from '../../contexts/SessionContext';
import { useLocation } from 'react-router-dom';

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#A9A9A9', // Dark grey for light theme
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#98FB98', // Pale green for dark theme
    },
  },
});

const PerformingMedia = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [response, setResponse] = useState('');
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [speechSynth, setSpeechSynth] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [botHasResponded, setBotHasResponded] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [open, setOpen] = useState({});
  const [currentStreamingMessage, setCurrentStreamingMessage] = useState(null);
  const { sessionId, logout, users } = useSession();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [videoLoaded, setVideoLoaded] = useState(false);

  // Recupera l'avatar salvato da Google, se presente; altrimenti usa l'avatar di default
  const userAvatarToUse = localStorage.getItem('userAvatar') || userAvatar;

  useEffect(() => {
    if (location.state && location.state.username) {
      setUsername(location.state.username);
    } else {
      // Se non c'è un username nello state, cerca l'username corrispondente al sessionId
      const foundUsername = Object.entries(users).find(([key, value]) => value === sessionId)?.[0];
      if (foundUsername) {
        setUsername(foundUsername);
      }
    }
  }, [location, sessionId, users]);

  const wsRef = useRef(null);
  const messagesEndRef = useRef(null);
  const currentStreamingMessageRef = useRef(null);

  useEffect(() => {
    currentStreamingMessageRef.current = currentStreamingMessage;
  }, [currentStreamingMessage]);

  const handleSpeech = (text) => {
    if (speechSynth && speechSynth.speaking) {
      speechSynth.cancel();
      setSpeechSynth(null);
    } else {
      const synth = window.speechSynthesis;
      const utterThis = new SpeechSynthesisUtterance(text);

      utterThis.onend = () => {
        setSpeechSynth(null);
      };

      synth.speak(utterThis);
      setSpeechSynth(synth);
    }
  };

  const handleTextFieldFocus = () => {
    setIsFocused(true);
  };

  const handleTextFieldBlur = () => {
    setIsFocused(false);
  };

  const sendMessage = async (message) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const jsonMessage = JSON.stringify({ text: message });
      wsRef.current.send(jsonMessage);
      setIsSending(true);
    } else {
      console.error('WebSocket is not open.');
    }
  };

  const handleButtonPress = (prompt) => {
    setChatActive(true);

    // Utilizza userAvatarToUse al posto di userAvatar
    const userMessage = {
      sender: 'user',
      text: prompt,
      avatar: userAvatarToUse  // Modifica qui
    };
    const tempBotMessage = {
      id: 'temp',
      sender: 'bot',
      text: '',
      avatar: botAvatar
    };

    setMessages(prevMessages => [...prevMessages, userMessage, tempBotMessage]);

    if (prompt.endsWith('?')) {
      prompt = prompt.slice(0, -1);
    }
    sendMessage(prompt);
    setBotHasResponded(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!message.trim()) {
      return;
    }
    // Utilizza userAvatarToUse al posto di userAvatar
    const userMessage = {
      sender: 'user',
      text: message,
      avatar: userAvatarToUse  // Modifica qui
    };
    const tempBotMessage = {
      id: 'temp',
      sender: 'bot',
      text: '',
      avatar: botAvatar
    };
    setMessages(prevMessages => [...prevMessages, userMessage, tempBotMessage]);
    sendMessage(message);
    setBotHasResponded(false);
    setMessage('');
  };

  const handleChatToken = useCallback((data) => {
    setIsStreaming(true);
    setMessages(prevMessages => {
      const newMessages = [...prevMessages];
      const tempBotMessageIndex = newMessages.findIndex(msg => msg.id === 'temp');
      if (tempBotMessageIndex !== -1) {
        newMessages[tempBotMessageIndex] = {
          ...newMessages[tempBotMessageIndex],
          text: newMessages[tempBotMessageIndex].text + data.content
        };
      }
      return newMessages;
    });
  }, []);

  const handleChatEnd = useCallback((data) => {
    setIsStreaming(false);
    setMessages(prevMessages => {
      const newMessages = [...prevMessages];
      const tempBotMessageIndex = newMessages.findIndex(msg => msg.id === 'temp');
      if (tempBotMessageIndex !== -1) {
        newMessages[tempBotMessageIndex] = {
          ...newMessages[tempBotMessageIndex],
          questions: data.relatedquestions ? JSON.parse(data.relatedquestions).questions : []
        };
        newMessages[tempBotMessageIndex].id = null; // Rimuove l'id 'temp'
      }
      return newMessages;
    });
    setCurrentStreamingMessage(null);
    setBotHasResponded(true);
    setIsSending(false);
  }, []);

  const handleChat = useCallback((data) => {
    let relatedQuestions = [];
    if (data.relatedquestions) {
      const parsedRelatedQuestions = JSON.parse(data.relatedquestions);
      if (parsedRelatedQuestions && parsedRelatedQuestions.questions) {
        relatedQuestions = parsedRelatedQuestions.questions;
      }
    }

    let botMessage = {
      sender: 'bot',
      text: data.content,
      avatar: botAvatar,
      questions: relatedQuestions
    };

    setMessages(prevMessages => {
      const newMessages = [...prevMessages];
      const tempBotMessageIndex = newMessages.findIndex(msg => msg.id === 'temp');
      if (tempBotMessageIndex !== -1) {
        newMessages.splice(tempBotMessageIndex, 1);
      }
      return [...newMessages, botMessage];
    });
    setBotHasResponded(true);
    setIsSending(false);
  }, []);

  useEffect(() => {
    wsRef.current = new WebSocket(`wss://aibook.nuvolaproject.cloud/ws/${sessionId}`);
    const ws = wsRef.current;

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      console.log('Received message:', event.data);
      try {
        const data = JSON.parse(event.data);
        setResponse(data);

        if (data.type === 'chat_token') {
          handleChatToken(data);
        } else if (data.type === 'chat_end') {
          handleChatEnd(data);
        } else if (data.type === 'chat') {
          handleChat(data);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
    ws.onclose = (event) => {
      console.log('WebSocket connection closed', event);
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [handleChatToken, handleChatEnd, handleChat]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    document.body.style.backgroundColor = darkMode ? '#2c2c2c' : '#f5f5f5';
  }, [darkMode]);

  useEffect(() => {
    const userMessage = messages.find(msg => msg.sender === 'user');
    if (userMessage) {
      setChatActive(true);
    }
  }, [messages]);

  const handleThemeChange = (event) => {
    setDarkMode(event.target.checked);
  };

  const questionChips = [
    "Cosa è l'innovazione adattiva?",
    "Che cosa è un walkabout?",
    "Chi è A.I.nfante?",
    "Chi ti ha creato?"
  ];

  const handleLogout = () => {
    localStorage.removeItem('sessionData');
    localStorage.removeItem('userAvatar');
    logout();
    if (wsRef.current) {
      wsRef.current.close();
    }
    window.location.href = '/login';
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box className={`performing-media-container ${darkMode ? 'dark-mode' : ''}`}>
        <AppBar position="fixed" color="default" className={`performing-media-header ${darkMode ? 'dark-mode' : ''}`}>
          <Toolbar>
            <Box display="flex" alignItems="center">
              <AutoStoriesIcon className="mobile-icon" fontSize='large' color={darkMode ? 'primary' : 'action'} />  
              <Typography variant="h6" className="title">
                Performing Media - un futuro remoto v.2
              </Typography>
              <IconButton onClick={() => setSidebarOpen(true)} disabled={!botHasResponded} sx={{ opacity: botHasResponded ? '0.3' : '0' }}>
                <PsychologyAltIcon fontSize='large'  color={darkMode ? 'primary' : 'action'} />
              </IconButton>
            </Box>
            <Box flexGrow={1} />
            <Button onClick={handleLogout}>Logout</Button>
            <Switch 
              checked={darkMode} 
              onChange={handleThemeChange} 
              sx={{ 
                color: darkMode ? '#98FB98' : '', 
                '&.Mui-checked': {
                  color: darkMode ? '#98FB98' : '',
                },
                '&.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: darkMode ? '#98FB98' : '',
                },
                '& .MuiSwitch-thumb': {
                  backgroundColor: darkMode ? '#98FB98' : '',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: darkMode ? '#98FB98' : '',
                }
              }}  
            />
          </Toolbar>
        </AppBar>
        <Sidebar response={response} open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        {!chatActive && (
          <>
            <Box className="animation-container">
              <video autoPlay loop muted>
                <source src={animationVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
            <Box className="button-container" sx={{ display: { xs: 'flex', sm: 'grid' }, gridTemplateColumns: '1fr 1fr', gap: 1, justifyContent: 'center', padding: 2 }}>
              {questionChips.map((chip, index) => (
                <Button
                  key={index}
                  className='question-chip'
                  onClick={() => handleButtonPress(chip)}
                  sx={{ my: 1 }}
                >
                  {chip}
                </Button>
              ))}
            </Box>
          </>
        )}
        <Box className="chat-messages-container">
          {messages.map((msg, index) => (
            <Box key={index} className={`bubble-container ${msg.sender} ${darkMode ? 'dark-mode' : ''}`}>
              <MessageBubble 
                setSidebarOpen={setSidebarOpen} 
                msg={msg} 
                index={index} 
                handleSpeech={handleSpeech} 
                botHasResponded={botHasResponded} 
                darkMode={darkMode} 
                speechSynth={speechSynth}
                username={username}
              />
              {msg.questions && msg.questions.length > 0 && (
                <>
                  <Box display="flex" justifyContent="center" style={{ marginBottom: '20px' }}>
                    <Button onClick={() => setOpen(prevOpen => ({ ...prevOpen, [index]: !prevOpen[index] }))}>
                      <ExpandMoreIcon />
                    </Button>
                  </Box>
                  <Collapse in={open[index]}>
                    <Box className="question-container" sx={{ display: { xs: 'flex', sm: 'grid' }, gridTemplateColumns: 'repeat(2, 1fr)', gap: 1, justifyContent: 'center', padding: 2,  marginBottom: '20px' }}>
                      {msg.questions.map((question, qIndex) => (
                        <Button key={qIndex} className='question-chip' onClick={() => handleButtonPress(question)}>
                          {question}
                        </Button>
                      ))}
                    </Box>
                  </Collapse>
                </>
              )}
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Box>
        <Box className="chat-form-container">
          <form onSubmit={handleFormSubmit} className={`chat-input-form ${darkMode ? 'dark-mode' : ''}`}>
            <TextField 
              id="message-textfield"
              multiline
              minRows={1}
              maxRows={9}
              fullWidth
              label={!isFocused && !message ? "Esplora il mondo di Performing Media ..." : " "}
              type="text"
              variant="standard"
              value={message}
              InputProps={{
                disableUnderline: true,
                className: 'input-field',
                style: { 
                  minHeight: 40,
                  maxHeight: 360,
                  overflowY: 'hidden',
                },
              }}
              InputLabelProps={{
                shrink: false,
                className: isFocused || message ? 'input-label-focused' : '',
                style: {
                  fontFamily: 'monospace',
                  opacity: 0.5,
                },
              }}
              onFocus={handleTextFieldFocus}
              onBlur={handleTextFieldBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleFormSubmit(e);
                }
              }}
              onChange={(e) => setMessage(e.target.value)}
            />
            <IconButton color="primary" aria-label="send" type="submit"  disabled={!message.trim() || isSending}>
              <SendIcon id="send-button" />
            </IconButton>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PerformingMedia;