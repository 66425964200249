// src/contexts/SessionContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(null);
  const [users, setUsers] = useState({});

  useEffect(() => {
    const savedUsers = JSON.parse(localStorage.getItem('users') || '{}');
    setUsers(savedUsers);
    
    const savedSessionId = localStorage.getItem('sessionId');
    if (savedSessionId) {
      setSessionId(savedSessionId);
    }
  }, []);

  const normalizeUsername = (username) => username.toLowerCase();

  const login = (username) => {
    const normalizedUsername = normalizeUsername(username);
    let userId;
    let uniqueUsername = username; // Manteniamo il caso originale per la visualizzazione

    // Cerca se l'utente esiste già (case-insensitive)
    const existingUser = Object.entries(users).find(([key, value]) => 
      normalizeUsername(key.split('-')[0]) === normalizedUsername
    );

    if (existingUser) {
      // Se l'utente esiste, usa il suo username e userId esistenti
      [uniqueUsername, userId] = existingUser;
    } else {
      // Se l'utente non esiste, crea un nuovo userId
      userId = uuidv4();
      
      // Cerca il prossimo numero disponibile per l'username se necessario
      let counter = 0;
      while (Object.keys(users).some(key => normalizeUsername(key) === normalizeUsername(uniqueUsername))) {
        counter++;
        uniqueUsername = `${username}-${counter.toString().padStart(2, '0')}`;
      }
    }

    // Aggiorna gli utenti e salva
    const updatedUsers = { ...users, [uniqueUsername]: userId };
    setUsers(updatedUsers);
    localStorage.setItem('users', JSON.stringify(updatedUsers));

    // Imposta e salva la sessione
    setSessionId(userId);
    localStorage.setItem('sessionId', userId);

    return uniqueUsername;
  };

  const logout = () => {
    setSessionId(null);
    localStorage.removeItem('sessionId');
  };

  return (
    <SessionContext.Provider value={{ sessionId, login, logout, users }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);