// src/components/MessageBubble.js
import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography, Tooltip, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { marked } from 'marked';
import defaultUserAvatar from '../assets/user.png';
import botAvatar from '../assets/bot.png'; // Assicurati che il percorso sia corretto

const processMarkdown = (text) => marked.parse(text);

const generateColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

// Componente per i puntini lampeggianti
const BlinkingDots = () => (
  <Box sx={{ display: 'flex', gap: 0.5 }}>
    <span className="dot" />
    <span className="dot" />
    <span className="dot" />
  </Box>
);

const MessageBubble = ({ setSidebarOpen, msg, handleSpeech, botHasResponded, darkMode, speechSynth, username }) => {
  const displayName = username ? username.trim() : '';

  // Legge l'avatar salvato da localStorage
  const [userAvatarToShow, setUserAvatarToShow] = useState(defaultUserAvatar);

  useEffect(() => {
    const storedAvatar = localStorage.getItem('userAvatar');
    if (storedAvatar && (storedAvatar.startsWith('http') || storedAvatar.startsWith('data:'))) {
      setUserAvatarToShow(storedAvatar);
    } else {
      setUserAvatarToShow(null);
    }
  }, [username]);

  // Se il messaggio è dell'utente, usiamo l'avatar dell'utente, altrimenti usiamo msg.avatar se esiste, altrimenti botAvatar
  const avatarUrl = msg.sender === 'user'
    ? (userAvatarToShow || defaultUserAvatar)
    : (msg.avatar || botAvatar);

  return (
    <Box className={`message-bubble ${msg.sender}`}>
      <Avatar
        src={avatarUrl || ''}
        alt={`${msg.sender} Avatar`}
        imgProps={{
          crossOrigin: "anonymous",
          onError: (e) => {
            e.target.onerror = null;
            e.target.src = '';
          }
        }}
        sx={{ bgcolor: avatarUrl ? undefined : generateColor(username) }}
      >
        {displayName ? displayName.charAt(0).toUpperCase() : ''}
      </Avatar>
      <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px' }}>
        <Typography variant="body1" component="h4" style={{ fontWeight: 'bold' }}>
          {msg.sender === 'user' ? displayName : 'A.I.nfante'}
        </Typography>
        {msg.sender === 'bot' && msg.id === 'temp' && (!msg.text || msg.text.trim() === '')
          ? <BlinkingDots />
          : typeof msg.text === 'string'
            ? <Typography dangerouslySetInnerHTML={{ __html: processMarkdown(msg.text) }} />
            : msg.text
        }
        {msg.sender === 'bot' && botHasResponded && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <Tooltip title="Perché questa risposta?">
              <IconButton
                onClick={() => setSidebarOpen(true)}
                disabled={!botHasResponded}
                style={{ color: darkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)', fontSize: '.5em' }}
              >
                <LiveHelpIcon fontSize="small" style={{ opacity: '0.7' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copia il testo">
              <IconButton
                onClick={() => navigator.clipboard.writeText(msg.text)}
                style={{ color: darkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)', fontSize: '.5em' }}
              >
                <FileCopyIcon fontSize="small" style={{ opacity: '0.7' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Pronuncia il testo">
              <IconButton
                onClick={() => handleSpeech(msg.text)}
                style={{ color: darkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)', fontSize: '.5em' }}
              >
                {speechSynth && speechSynth.speaking ?
                  <VolumeOffIcon fontSize="small" style={{ opacity: '0.7' }} /> :
                  <VolumeUpIcon fontSize="small" style={{ opacity: '0.7' }} />
                }
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MessageBubble;