// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useSession } from '../contexts/SessionContext';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import {
  Button,
  TextField,
  Box,
  Typography,
  Link,
  Grid
} from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import copertinaImage from '../assets/copertinaperformingmedia.jpg';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

const Login = () => {
  const [username, setUsername] = useState('');
  const { login, sessionId } = useSession();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Al mount: se esiste una sessione salvata, la ripristiniamo
  useEffect(() => {
    const savedSession = localStorage.getItem('sessionData');
    if (savedSession) {
      const sessionObj = JSON.parse(savedSession);
      login(sessionObj.username);
      // Inoltre, ripristiniamo l'avatar in localStorage con chiave userAvatar
      if (sessionObj.userAvatar) {
        localStorage.setItem('userAvatar', sessionObj.userAvatar);
      }
      navigate('/performing-media');
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const uniqueUsername = login(username);
    // In caso di login manuale, salviamo solo il nome; l'avatar di default verrà usato in MessageBubble
    const sessionObj = { username: uniqueUsername, userAvatar: null };
    localStorage.setItem('sessionData', JSON.stringify(sessionObj));
    navigate('/performing-media', { state: { username: uniqueUsername } });
  };

  const googleLogin = useGoogleLogin({
    scope: 'openid profile email',
    onSuccess: async (tokenResponse) => {
      try {
        const profileResponse = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
        const profileData = await profileResponse.json();
        // Se il campo name è vuoto o composto solo da numeri, usiamo l'email
        const isNumeric = (str) => /^\d+$/.test(str);
        const usernameFromGoogle =
          (profileData.name && !isNumeric(profileData.name) && profileData.name.trim().length > 0)
            ? profileData.name
            : (profileData.email || tokenResponse.access_token);
            
        // Convertiamo l'immagine in Base64 per salvarla e usarla senza ulteriori richieste
        let avatarBase64 = null;
        if (profileData.picture) {
          try {
            const res = await fetch(profileData.picture, { mode: 'cors' });
            const blob = await res.blob();
            avatarBase64 = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
          } catch (err) {
            console.error("Errore nella conversione in Base64 dell'avatar:", err);
            // Se fallisce, usiamo l'URL originale (anche se può causare errori 429)
            avatarBase64 = profileData.picture;
          }
        }
        // Creiamo l'oggetto sessionData
        const sessionObj = {
          username: usernameFromGoogle,
          userAvatar: avatarBase64, // potrà essere null se non presente
        };
        localStorage.setItem('sessionData', JSON.stringify(sessionObj));
        // Salviamo anche l'avatar separatamente per MessageBubble
        if (avatarBase64) {
          localStorage.setItem('userAvatar', avatarBase64);
        }
        login(usernameFromGoogle);
        navigate('/performing-media');
      } catch (error) {
        console.error("Errore nel recuperare il profilo utente:", error);
      }
    },
    onError: (error) => {
      console.log("Google login error:", error);
    },
  });

  const handleGoogleLogin = () => {
    googleLogin();
  };

  if (sessionId) {
    navigate('/performing-media');
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid
            item
            xs={0}
            md={6}
            sx={{
              display: { xs: 'none', md: 'flex' },
              backgroundColor: '#f5f5f5',
              height: '100%',
              p: 4,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              component="img"
              src={copertinaImage}
              alt="Copertina Performing Media"
              sx={{
                width: '90%',
                maxWidth: 400,
                borderRadius: 2,
                boxShadow: 3,
                mb: 2,
              }}
            />
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Link href="https://libridi.it/futuremoto" target="_blank" rel="noopener" underline="hover">
                  Scopri di più sul libro <strong>Performing Media - un futuro remoto</strong>
                </Link>
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Link href="https://libridi.it" target="_blank" rel="noopener" underline="hover">
                  Scopri di più sul progetto editoriale <strong>Libridi</strong>
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link href="https://nuvolaproject.cloud" target="_blank" rel="noopener" underline="hover">
                  Scopri di più su <strong>NuvolaProject</strong>
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundColor: '#ffffff',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ width: { xs: '80%', sm: '80%', md: '60%' }, textAlign: 'center' }}>
              {isMobile && (
                <Box
                  component="img"
                  src={copertinaImage}
                  alt="Miniatura copertina"
                  sx={{
                    width: 100,
                    height: 'auto',
                    borderRadius: 1,
                    boxShadow: 1,
                    mb: 2,
                    mx: 'auto',
                  }}
                />
              )}
              <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                Performing Media - un futuro remoto
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,
                    borderRadius: '4px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Login
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleLogin}
                  sx={{
                    mt: 1,
                    mb: 2,
                    borderRadius: '4px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Login with Google
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Login;